<template>
  <div class="pad-container">
    <p class="c-9">{{pageTitle}}</p>
    <el-input
      type="textarea"
      placeholder="请输入内容"
      v-model="content"
      rows="10"
    ></el-input>
    <div class="flx-row ali-c js-fe mt-50">
      <el-button class="form-button" type="primary" @click="save">保存</el-button>
    </div>
  </div>
</template>
<script>
import request from "@/utils/request"
export default {
  props: ['pageTitle', 'protocolType'],
  data() {
    return {
      content: "",
      protocolId: "",
    };
  },
  created() {
    this.getProtocol()
  },
  methods: {
    getProtocol() {
      request({
        url: "/user-service/serviceAgreement/" + this.protocolType,
        method: "get"
      }).then(res => {
        if(res.data.code == 200) {
          this.content = res.data.body.content
          this.protocolId = res.data.body.id
        }
      })
    },
    save(e) {
      let data = {
        content: this.content,
        type: this.protocolType
      }
      let method = "post"
      if(this.protocolId) {
        data.id = this.protocolId
        method = "put"
      }
      request({
        url: "/user-service/serviceAgreement",
        method,
        data
      }).then(res => {
        if(res.data.code == 200) {
          this.$message({
            message: "操作成功",
            type: "success"
          })
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped></style>
